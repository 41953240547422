import React from 'react';
import { Container, Grid, Header } from 'semantic-ui-react';

export default () => (
  <Container className="shadow" style={{ background: '#222' }}>
    <Grid columns="equal" style={{ background: '#222', margin: 0 }}>
      <Grid.Row style={{ marginBottom: -12 }}>
        <Grid.Column verticalAlign="bottom" style={{ marginBottom: 4 }}>
          <Header as="h1" style={{ color: '#fff', fontWeight: '100' }}>
            Detecht FAQ
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column style={{ color: '#fff' }} className="white-text">
          <br />
          <Header.Subheader>Crash Detection</Header.Subheader>
          The crash detection algorithm is based on monitoring the smartphones key sensors (Gyroscope, Accelerometer,
          Magnetometer and GPS). When an unusual driving pattern occurs equal to an motorcycle accident such as
          deceleration and tumbling followed by a no movement pattern. If the driver starts to move and/or continue
          riding after an accident, the crash alarm will not go off. If a crash is detected, an alarm notification is
          activated on the drivers phone symbolising alarm sound and vibrations. The driver now has 60 seconds to
          deactivate the alarm if the driver is unharmed and don’t need any emergency help. Today, the Detecht are
          directly linked to the emergency service SOS Alarm in Sweden available for all Swedish riders for free. In
          order to get emergency help as fast as possible we send your exact location, driving direction, time of
          accident, and the drivers phone number. In order for the SOS Alarm connection to work you need to activate SOS
          Alarm under your User Settings entering you required personal information.
          <br />
          <br />
          <Header.Subheader>Do I Need Any External Sensors?</Header.Subheader>No, you have all the sensors you need
          right in your smartphone!
          <br />
          <br />
          <Header.Subheader>Where should I place the mobile phone while driving?</Header.Subheader>The mobile phone has
          to be worn on the body of the driver or mounted on the motorcycles handlebar. You should not place it in any
          backpack, open space or similar, then the accident detection can false alarm due to the sensitivity in the
          phone’s sensors.
          <br />
          <br />
          <Header.Subheader>What is sent to SOS Alarm?</Header.Subheader>Detecht only send the most critical information
          to SOS Alarm, and no information that could hurt a driver involved in an accident. The following information
          is sent from Detecht to SOS Alarm in order for them to be able to locate and help the driver: Time of an
          accident, position (GPS), direction, name, year of birth and phone number. The phone number is used by SOS
          Alarm to try to get in touch with the driver.
          <br />
          <br />
          <Header.Subheader>The GPS positioning is not correct?</Header.Subheader>In some locations the phone might not
          be able to get good GPS connection. You can still use Detecht, but distance and velocity will become less
          accurate. If it is not tracked at all it is probably due to that you did not allow the app to use it’s
          positing. Enable Detecht to do so by go to settings > apps > permissions and allow Detecht to use your
          position. Edit the accuracy to high precision as well.
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
);
