import React from 'react';
import { Container, Grid, Header } from 'semantic-ui-react';

export default () => (
  <Container className="shadow" style={{ background: '#222' }}>
    <Grid columns="equal" style={{ background: '#222', margin: 0 }}>
      <Grid.Row style={{ marginBottom: -12 }}>
        <Grid.Column verticalAlign="bottom" style={{ marginBottom: 4 }}>
          <Header as="h1" style={{ color: '#fff', fontWeight: '100' }}>
            Detecht TERMS OF USE
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column style={{ color: '#fff' }} className="white-text">
          <b>Ref: ADMIN/NO/201806/26</b>
          <p>
            Thank you for considering the use of our services. We hope that you will complete your registration but
            before you do so, you should read the contents of this document, which sets out the legal foundations for
            the relationship you have with us. Below you will find our Terms and Conditions of Use. This document forms
            the contractual arrangement between us, which comes into force when you complete your registration. Below
            the Terms and Conditions of Use you will find terms and conditions that relate to the delivery to you of
            other services by third parties. Your contract concerning those other services is with the third party
            provider in question, so you should read the applicable terms and conditions before making use of them.
          </p>
          <br />
          <Header.Subheader>TERMS AND CONDITIONS OF USE</Header.Subheader>
          The information below tells you how you may use the Detecht app and other services provided by Detecht for
          your own personal, non-commercial use. Please read these terms and conditions (the “Terms”) carefully before
          using Detecht’s services. Please note that Detecht updates these Terms from time to time so please review them
          regularly. These terms were last updated on 26 June 2018.
          <br />
          <br />
          <Header.Subheader>1. ABOUT THESE TERMS AND CONDITIONS</Header.Subheader>
          What do these Terms cover?
          <br />
          These Terms set out important information regarding your rights and obligations, and the restrictions that may
          apply, when you: use any of our services: for these purposes, our services include (but are not limited to)
          the provision of online content, online social media and/or interactive applications, audio visual content,
          text, photographs, advertising, app and any similar service that we currently provide or make available in the
          future; access our platform or devices (the “Platforms”): our Platforms include (but are not limited to) the
          internet (including but not limited to the website www.detecht.se and all its sub-domains (the “Website”));
          other media platforms and software applications (which, for example, may enable you to view and use our
          content and/or services over a mobile or Wi-Fi network); other portable or non-portable communication or other
          electronic device; and any similar platforms that we currently provide or make available in the future,
          (together referred to as “Services”); subscribe to use additional services delivered via the Platforms (the
          “Subscription Services”, being part of the Services). Our references below to the Services should be taken as
          including the Subscription Services unless we state to the contrary.
          <br />
          Who uses and who provides the Services?
          <br />
          References to “you” or “your” mean that you as a user of the Services. References to “we”, “us”, “our” or
          “Detecht” are to the provider of the Service, which is a company registered in Sweden with registered office
          at Stena Center 1B, 412 92 Gothenburg. If you have any questions, queries or complaints about these Terms or
          the Services, please contact us at email: info@detecht.se or postal address of the registered office. If you
          download an app from Apple’s AppStore or Google Play, you are forming a contract not with Apple or Google but
          with us. Neither Apple nor Google will provide support for your app and you should contact us, not Apple or
          Google, concerning any problems you have through the facilities we provide for that purpose. However, if you
          are unhappy with the quality of the app, you can express your discontent to Apple or Google through either
          system and, if appropriate, obtain a refund of any payments you’ve made for or through the app concerned. Why
          should you read these Terms?
          <br />
          Please read these Terms (and any applicable third party Terms of Use and Privacy Policies) carefully before
          using the Services. By accessing and continuing to use the Services, you agree to be legally bound by the
          version of these Terms in force at that time. If you do not agree with any of our Terms then you should not
          use our Services. Will these Terms change? Please note that we may update and amend these Terms from time to
          time and the current version of these terms will be posted on the Website. We will endeavour to notify you
          when we post new Terms. The version of these Terms that is current at the time you use the Services will apply
          to your use of those Services. It is your responsibility to ensure you review these Terms regularly to
          familiarise yourself with any changes. Your use of the Services following any such changes will constitute
          your acceptance of the revised Terms. Will any other Terms and Conditions apply to use of the Services? In
          addition to these Terms, the following terms and conditions may also apply to your use of the Services:
          Detecht Service-Specific Terms: You may be asked to agree to additional terms and conditions (“Additional
          Terms and Conditions”) when using specific Services, for example, before entering competitions or prize draws
          promoted by us or our partners, submitting materials, subscriptions or purchasing goods via the Services, or
          using any of our applications which may be available. If there is any inconsistency between these Terms and
          any such Additional Terms and Conditions, the Additional Terms and Conditions will prevail to the extent of
          such inconsistency. Data Protection Terms: Please read our Privacy Policy, which are posted on our Website.
          They contain important information about how Detecht uses any personal information about you that it receives
          through your use of the Services. If there is any inconsistency between these Terms and the terms of our
          Privacy Policy, the Privacy Policy will prevail to the extent of such inconsistency. Third Party Terms: Please
          also see the section below entitled “Third party content and services” regarding certain pages and services
          that are provided on our Website or via the Services and which are managed, hosted, delivered and/or operated
          by a third party.
          <br />
          <br />
          <Header.Subheader>2. RESPONSIBILITY FOR THE AVAILABILITY AND CONTENT OF SERVICES</Header.Subheader>
          Who is responsible for the availability and content of the Services? Save for the Subscription Services, the
          Services are made available to you by Detecht at our sole and absolute discretion and we may modify or
          withdraw those Services, or the period during which they are available, at any time at our sole and absolute
          discretion. We may do this without notice to you. We have absolute editorial control over the Services
          (including all the material and/or content made available by Detecht via a Platform and/or the Services) at
          all times. We deliver the Subscription Services to you on a rolling basis. You will pay for the Subscription
          Services on a weekly, monthly or yearly recurring basis and we are obliged to deliver the Subscription
          Services to you during the period of time that you have paid for, subject to our right to terminate under
          these terms and conditions or the interruption of those Subscription Services by events beyond our reasonable
          control. We will cease delivery of the Subscription Services at the end of the period for which you have
          subscribed unless you have renewed your subscription. We will attempt to notify you of the approaching expiry
          of your subscription but we will not be liable if you fail to receive that notification or if you do receive
          the notification but choose to ignore it.
          <br />
          <br />
          <Header.Subheader>3. WHO CAN USE OUR SERVICES</Header.Subheader>
          Who can use our Services?
          <br />
          If you are 18 years of age or over and you are capable of entering into contracts for yourself (which is to
          say, you are not suffering from a mental disability), you may use our Services including the Subscription
          Services. Can children and under-18s use the Services?
          <br />
          If you are under 18 years old:
          <br />
          you must have your parent’s or guardian’s permission before using our Services; and you must read these Terms
          with your parent or guardian to make sure you and your parent or guardian understands and agrees to these
          Terms; and your parent or guardian must agree to these Terms on your behalf before you use our Services. Is
          the content of the Services suitable for children? Some of the content of our Services will not have been
          moderated by Detecht and/or may contain strong language or material which may not be suitable for children. We
          will do our best to identify such content and to give you as much guidance as possible for you to make an
          informed choice as to whether you wish to enable your child to view this content, but we cannot warrant that
          all content will be suitably identified. By accessing and continuing to use the Services, you accept
          responsibility for reviewing such guidance and information and, where you allow anyone under the age of 18 to
          access the Services, for ensuring that the Services are suitable for them. Should you wish your child to make
          use of the crash detection service, you are responsible for ensuring that s/he can understand how to respond
          to an alert generated mistakenly by the app. If you choose to make use of the crash detection service on your
          child’s behalf, you are responsible for ensuring that the information forwarded to the emergency services is
          correct and up to date and you understand that we cannot guarantee the successful transmission of this
          information to the first responders.
          <br />
          <br />
          <Header.Subheader>4. YOUR RIGHTS AND OBLIGATIONS WHEN USING THE SERVICES</Header.Subheader>
          What must you comply with to use the Services?
          <br />
          You may use the Services only if you comply with these Terms, any other applicable terms (including but not
          limited to our Privacy Cookie and Cookie Policy and any applicable Additional Terms and Conditions and/or any
          specific usage rules that may be posted in relation to any material and content that you access via the
          Services and/or any third party terms) and with all applicable laws. Likewise, you may use services delivered
          by third parties only if you comply with the terms and conditions applicable to the delivery of those services
          by those third parties. What are your obligations?
          <br />
          By accessing any part of the Services (including any material or content made available by Detecht via the
          Services), you agree that you will only use such Services:
          <br />
          for your own personal, non-commercial use;
          <br />
          for lawful purposes; and in a manner that does not infringe the rights (e.g. copyright) of, or restrict or
          inhibit the use and enjoyment of the Services by, any third party. You understand and accept that the hardware
          you use to access our Services may require certain software in order for the Services to work correctly and it
          is your sole responsibility to ensure that you have the required software before accessing those Services. In
          order to use the Services, you may be required to obtain certain updates and/or upgrades to your hardware. You
          are solely responsible for any costs and/or fees associated with any such updates/upgrades to any hardware or
          software. You agree to indemnify us in respect of any damages or losses suffered by us as a result of any
          claim made or action taken by a third party relating to (i) your use of the Services in breach of these Terms
          (or any other applicable terms), or (ii) your violation of any applicable law or regulation when using the
          Services. You promise to us that you are authorised to agree to these Terms and any other terms that apply to
          the use of our Services, including (but not limited to) our Privacy Policy and Cookie Policy and any
          applicable Additional Terms and Conditions and/or any specific usage rules that may be posted in relation to
          any material and content that you access via the Services and/or any third party terms.
          <br />
          <br />
          <Header.Subheader>5. RESTRICTIONS ON USING OUR SERVICES</Header.Subheader>
          What are the restrictions on using our Services?
          <br />
          By accessing any part of the Services (including any material or content made available by Detecht via the
          Services), you agree that you will not: use such Services to reverse engineer, decompile, disassemble, copy,
          reproduce, communicate and/or make available to the public, republish, download, post, record, broadcast or
          transmit or use in any other way the Services (or any part thereof including but not limited to any content or
          software code) except as permitted by us; use such Services in any way that may lead to the encouragement,
          procurement or carrying out of any criminal or unlawful activity, or cause distress, harm or inconvenience to
          any other person; use such Services in any way to infringe the privacy or other rights of other users of the
          Services or to post material that is offensive, obscene, abusive, libellous, false, misleading, illegal,
          immoral or otherwise unlawful; charge (whether directly or indirectly) others to use all or any part of the
          Services commercialise or attempt to commercialise all or any part of the Services; permit or assist in any
          way any third party to use the material or content made available by Detecht via the Services in an unlawful
          manner or in a manner that could infringe the rights of, or restrict or inhibit the use and enjoyment of the
          Services by, any third party; utilise ‘plugins’ or any application(s), software or associated tool(s) which
          change the content of the Services; do anything that may cause damage to the Services or our servers, systems
          or equipment or those of third parties, nor access or attempt to access any users’ data; do anything that
          subjects the Services or Detecht to any derogatory treatment or brings (or might bring) the Services or
          Detecht into disrepute; misuse or do anything that disrupts all or any part of the Services, including but not
          limited to knowingly introducing software viruses, trojans, worms or other material that may be
          technologically harmful; penetrate, remove or otherwise alter or interfere with any security measures that we
          use to protect the material and content made available via our Services, or attempt to do so or assist any
          other person in doing or attempting to do so; or state, suggest or in any way give the impression that you
          have any commercial relationship with us, or that you are our agent or that we have endorsed any submission
          you may make to any of our Services. Do you need to register to use the Services? You are required to register
          your details for Services and open a user account that you can then use to access and log on to use those
          Services (your “Detecht Account”). You may change or update your user name and password or other details at
          any time via your Detecht Account. You promise that all information and details provided are true, accurate
          and up to date in all respects and at all times. You are responsible for all activity that occurs under your
          user name and password. You must keep any password confidential and we will not be responsible where your
          password is used by someone else. You should notify us immediately of any unauthorised use of your Detecht
          Account and any breach of security as soon as you become aware of it. Please choose a password that is not
          only memorable but also reasonably secure. We recommend steering clear of birthdays and names. A combination
          of letters and numbers is usually the minimum for any sensible level of security, but you might like to use
          punctuation as well as a combination of upper and lower case characters. Most web accounts are hacked using
          ‘social engineering’, in short: the guessing of passwords. If your account is hacked, we will not be
          responsible for any damage that you suffer, so please do your best to keep your registration details secure.
          <br />
          <br />
          <Header.Subheader>6. Detecht’s RIGHTS, OBLIGATIONS AND LIMITS ON LIABILITY</Header.Subheader>
          What are our rights in relation to your use of the Services? We reserve the right to suspend, restrict or
          terminate your access to all or any part of the Services with or without notice at any time at our sole and
          absolute discretion. We may also permanently ban or temporarily suspend you from using the Services if you do
          not comply with these Terms or any other applicable terms, at our sole and absolute discretion. What are our
          obligations and limits on liability? We will use reasonable endeavours to deliver the Subscription Services as
          described in the Website and App from time to time. All other Services are provided on an ‘as is’ and ‘as
          available’ basis. Certain Services, such as the crash detection service, are available only in limited areas.
          Details of the geographical scope of those Services will be published on the Website from time to time. We
          will attempt to notify you in the event that you use our Services outside of such areas but such notifications
          are dependent upon many factors that are beyond our control and we will not be liable to you in the event that
          you fail to receive such a notification. We cannot promise that our Services will be free from errors or
          omissions nor that they will be available uninterrupted and in a fully operating condition, or free from
          viruses or other harmful components. Not all of the features and functionality of the Services will be
          available on all devices. We make no guarantee that all or any features of the Services will work on any
          particular device. While we take reasonable precautions to prevent the existence of computer viruses and/or
          other malicious programs, we accept no liability for them. The Services may be suspended temporarily and
          without notice for any reason including without limitation in the case of system failure, maintenance or
          repair or due to events reasonably beyond our control. Except as set out in the “Is payment required to use
          our Services” section below, we will not be liable to you or to any other person in the event that all or any
          part of the Services is unavailable, discontinued, modified or changed in any way. Where you are required to
          obtain certain updates and/or upgrades to your hardware or software in order to use the Services, you are
          solely responsible for any costs and/or fees associated with any such updates/upgrades and we make no
          guarantees in respect of the compatibility of your hardware or software with the Services and/or any
          updates/upgrades. In addition to other specific exclusions of liability set out in these Terms, we do not
          accept any liability for: any damage or loss caused to you while using our Services in breach of these Terms,
          including but not limited to where you are using our Services for purposes other than your own personal,
          non-commercial use; any damage to your device or any software; any loss of data that results from your use of
          the Services; any loss of income, revenue, business, profits or contracts that results from any alleged
          failure of the Services; any failure, suspension and/or termination of access to the Services and/or any
          content in connection with or arising out of an event which is outside our reasonable control (including but
          not limited to strikes, lock-outs or other industrial disputes, nuclear accident or acts of God, war or
          terrorist activity, riot, civil commotion, malicious damage, compliance with any law or government order, rule
          regulation or direction, accident or breakdown of plant or machinery, fire, flood, storm or default of
          suppliers, and where they are beyond our reasonable control, any other acts, events, omissions or accidents);
          any claims brought against you by a third party except as stated in these Terms; and/or any damage or loss
          caused to you where such damage or loss (i) is not reasonably foreseeable to you and us when you use the
          Services or (ii) is reasonably foreseeable to you and us but is only indirectly related to your use of the
          Services; in both cases including where the damage or loss results from our breach of these Terms. Any content
          or other materials included in the Services do not, and are not intended to, amount to advice on which you
          should rely. Consequently we do not accept any responsibility or liability for any actions or omissions that
          you may take in reliance on such content or materials. If you make use of the crash detection functionality
          within the app, we will use our best endeavours to contact your ICE-contacts and the emergency services in
          Sweden (for accidents happening in sweden), on your behalf however you should not rely on our ability to do so
          as there are several variables that can interfere with the crash detection function, such as damage to your
          device, network coverage or failure, or such like. Please also be aware that the crash detection function
          makes use of an algorithm that considers many factors and although we have subjected the algorithm to
          extensive testing, we cannot warrant that it will work perfectly in absolutely every circumstance. Wherever
          possible, please make sure you have other arrangements in place, such as sharing your route and making others
          aware of where you should be at any given time.
          <br />
          <br />
          <Header.Subheader>7. CREATIVE CONTENT AND INTELLECTUAL PROPERTY RIGHTS</Header.Subheader>
          Who owns the intellectual property in the Services?
          <br />
          All copyright, trademarks, design rights, patents and other intellectual property rights (whether registered
          or unregistered) in the Services and all material or content made available by Detecht via the Services
          (excluding any User Generated Content (see below)) are and will remain at all times the property of Detecht
          (or Detecht’s licensors or third parties, as applicable). Who can use the intellectual property in the
          Services?
          <br />
          The names, images and logos identifying us, our partners or third parties and our/their products and services
          contained in the Services are proprietary marks and may not be reproduced or otherwise used without our
          express permission. Nothing contained in these Terms grants you a licence or a right to use any copyright,
          trademarks, design rights, patents or other intellectual property right owned or controlled by Detecht, its
          licensors or any third party, except to the limited extent necessary to receive the Services. Can the content
          of the Services be copied?
          <br />
          You may not copy all or any part of any materials or content made available by Detecht via the Services except
          to the extent permitted in these Terms, or expressly by us, or at law. If the Services are accessed by an
          authorised person for or on behalf of an educational establishment for the non-commercial educational purposes
          of that educational establishment then, provided that the educational establishment: holds a current licence
          with the Educational Recording Agency (ERA); and complies with these Terms, the non-commercial access to our
          Services permitted by these Terms will also apply to non-commercial educational use within the scope of the
          ERA licence held by that educational establishment. Who is responsible for User Generated Content? The
          Services will incorporate content that is generated either by users of the Services (including you) or through
          third party applications/widgets (e.g. from “social media” platforms such as Facebook or Twitter) (“User
          Generated Content”). You agree and acknowledge that: our ability to control the User Generated Content is
          limited;
          <br />
          we do not endorse User Generated Content and cannot guarantee that it will meet the standards that content
          produced by us would ordinarily meet; and any views expressed in User Generated Content do not necessarily
          reflect our views.
          <br />
          Consequently we do not accept any responsibility or liability for User Generated Content or any actions or
          omissions that you may take in reliance on it. What additional terms apply to content submitted by you?
          <br />
          If you wish to submit any contribution to the Services or upload any content such as any text, photographs,
          graphics, video or audio to the Services (“Submission”), you agree that any Submission you make is subject to
          the following additional terms, except where expressly stated otherwise in any Additional Terms and Conditions
          that apply to a specific Service. You retain all ownership rights in any Submission you may make. You promise
          that your Submission is your own original work, and that you are the owner of any Submission you make or that
          you have all necessary licences, rights, consents, and permissions to authorise us to use your Submission. In
          particular, you promise that you have obtained the permission of all of the people featured or referred to in
          the Submission (and if they are under 18, the permission of their parents or guardians as well) to our use of
          the Submission on the Services. You agree to give us evidence of all such licences, rights, consents, and
          permissions if so requested by us. You acknowledge that the contents of any Submission material (other than
          personal data, the use of which will be governed by our Privacy Policy) will be considered non-confidential.
          By making a Submission to our Services, you grant us a licence (which means permission) to use, edit,
          reproduce, record, modify, translate, distribute, play, perform, make available to the other users of the
          Services, prepare derivative works of and to display your Submission by any medium or method whether now known
          or later developed to be exercised in our sole discretion throughout the world for the full term of copyright
          and other rights and all renewals and extensions thereof including, for the avoidance of doubt, the right to
          register any design rights and/or trademarks. You agree that this licence is: perpetual (which means it lasts
          forever); non-exclusive (which means you are free to grant permission to use the Submission to other parties);
          royalty-free (which means we won’t pay for the permission either now or in the future); and transferable
          (which means we can transfer the licence to any other third party) and you agree that we can sub-licence this
          license (which means we can grant an equivalent permission to other third parties in respect of your
          Submission). You also agree to waive your ‘moral rights’ in your Submission. You are responsible for the
          contents of any Submission you may make and the consequences arising from its use on our Platforms and/or as
          part of our Services. You will not upload any material or content which is in any way defamatory, obscene,
          unlawful, harmful, offensive or inappropriate or infringes the rights of a third party or may have the effect
          of being harassing, threatening, abusive or hateful or that otherwise degrades or intimidates an individual or
          group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age or disability.
          You agree not to commit any criminal offence or infringe any laws in producing or making a Submission. You
          agree not to transfer files which contain viruses or other harmful programs. Further, you must not endanger
          yourself or others, or take any unnecessary risks in order to produce or make a Submission. You also agree not
          to advertise or promote third parties’ or your own goods or services in any Submission, including but not
          limited to by way of the distribution of “spam” messages. In particular, you agree not to use the Services to
          provide links to third party websites. We may accept, modify, withdraw or refuse to display your Submission at
          any time at our sole and absolute discretion. We may do this without notice to you for any reason, including,
          without limitation, where we suspect your Submission to be in any way defamatory, obscene, unlawful, harmful,
          offensive, inappropriate or to infringe the rights of a third party. However, we have no obligation to modify
          or remove any inappropriate, offensive or unlawful User Generated Content or other content. Please note that
          we cannot accept any Submission containing any music, save where expressly permitted by us.
          <br />
          <br />
          <Header.Subheader>8. OTHER IMPORTANT POINTS TO NOTE ABOUT OUR SERVICES</Header.Subheader>
          Are my details kept safe? We use security measures to keep details you have provided to us safe but we
          recommend that you never use the Services to reveal to others any personal information about yourself or
          anyone else (for example: telephone number, home address or email address) unless you are absolutely certain
          that the recipient of such information can be trusted. You are entirely responsible for maintaining the
          confidentiality of your details when using our Services. If you wish to enable the crash detection
          functionality, you may choose to store medical information about yourself in the app. If the crash detection
          is triggered, the app will attempt to transfer this data from your device to the emergency services or
          ICE-contacts via our servers. Although the data will pass through our servers at that point, it is only stored
          transiently. At no point will we have access to that information and it will not be stored by us other than as
          necessary to facilitate the transmission. Please make sure that you switch the crash detection service off in
          the event that you lend your device to another. If you do not and they are involved in an accident, the
          transmission to the emergency services of your medical information could be extremely hazardous to and
          possibly fatal for that person.
          <br />
          <br />
          <b>Is payment required to use the Services?</b>
          <br />
          <b>Payments</b>
          <br />
          If you choose to become a premium subscriber, you must designate and provide information about your preferred
          payment method (e.g. credit card, online payment service or any other payment method made available by
          Detecht) (“Payment Method”). You may switch to a different Payment Method or update your information by
          visiting “Settings” or by contacting us by email at info@detecht.se. You will immediately be charged for your
          subscription fees after you click “Submit” on the confirmation page and your account will be activated to
          Premium status. You agree to pay all subscription fees and other charges incurred in connection with your
          username and password for your Detecht account.
          <b>Fees and Renewals</b>
          <br />
          Subscription fees are paid weekly, monthly or yearly. All fees are payable in advance. Subscription fees will
          be billed automatically to the Payment Method at the start of the period, as applicable, and will auto-renew
          until your subscription is terminated. The renewal subscription fees will be the same as the initial charges
          unless you are otherwise notified in advance. You authorise Detecht to charge your Payment Method for the
          appropriate subscription charges and fees and for any other purchases you elect to make via the Platform.
          Detecht reserves the right to increase subscription fees or to institute new fees at any time upon reasonable
          notice posted in advance on the Platform. ALL PURCHASES ARE FINAL AND NO REFUND WILL BE GIVEN FOR UNUSED
          PORTIONS OF YOUR INITIAL OR ANY RENEWAL SUBSCRIPTION PERIOD. Cancellation
          <br />
          You may cancel your Premium subscription by visiting “Settings” and selecting “Cancel Premium Subscription” or
          by contacting us at info@detecht.se. The cancellation of a subscription will go into effect at the end of your
          current billing cycle, and you will have the premium features through the remainder of such billing cycle.
          When your Premium subscription ends, your account will enter “Detechts Free” mode. No refunds or credits will
          be provided by Detecht upon cancellation. You can renew your subscription at anytime without opening a new
          account, provided that additional fees may apply if you decide to upgrade to Premium subscription at a later
          date. Can you link to our Website?
          <br />
          You may link your website to the home page of our Website, provided that you own that website and that you do
          so in a way that is fair, legal and does not take advantage of or damage our reputation and any such link is
          for non-commercial purposes. Any such link must not, suggest or in any way give the impression that you have
          any commercial relationship with us, or that we have approved or endorsed such link. You may not create a link
          to any part of our Website other than the home page. We reserve the right to withdraw permission to link to
          the home page of our Website at any time without notice at our sole and absolute discretion. You may not
          “deep-link” to pages beyond the home page without our express permission.
          <br />
          Can you frame our Website?
          <br />
          You may not frame our Website on other website without our express permission.
          <br />
          <br />
          <Header.Subheader>9. THIRD PARTY CONTENT AND SERVICES</Header.Subheader>
          Do third parties provide content and services on our Platforms? Please note that certain pages and services
          may be hosted, managed, delivered and operated by a third party. Where this is the case it will be indicated
          on the Website and/or the relevant service. Your use of those services will be the subject of terms and
          conditions with the third party in question and your contract in respect of the delivery of those Services
          will be with that third party. When registering to become a user of Detecht you accept those third party terms
          and conditions, which come into force the moment you activate a third party service to which they relate. If
          you do not consider those third party terms and conditions to be acceptable, you may continue to register to
          use the Services. Simply ignore and make no use of the third party service to which the terms and conditions
          you have rejected apply and those terms and conditions will not apply to you. The Services delivered by
          Detecht may contain links to other third party websites, platforms, goods, offers and services through
          advertising or otherwise, and in certain circumstances our content may also be made available on third party
          services and platforms (“Distributed Content”). Who is responsible for third party content and services?
          <br />
          The third parties delivering services to you via the Services are companies wholly independent of us and are
          solely responsible for all aspects of any transaction you may make using such services. They may have their
          own Privacy Policies and/or Terms and Conditions of Use. Your use of such third party websites and services
          will be governed by their Terms and Conditions and Privacy Policies, we therefore recommend you read those
          documents since you will be agreeing to comply with them. For the contact details of the applicable third
          party, see that third party’s Terms and Conditions or available or referred to on the applicable Platform. You
          are free to choose whether or not to make use of such third party services. Consequently we do not accept any
          responsibility or liability for content incorporated in our Services in this way or any actions or omissions
          that you may take in reliance on it. We are not responsible for the contents (including the Distributed
          Content) or availability of such third party websites or services (including the failure of any links to
          them). We do not endorse the material contained in their websites or services. Any links to third party
          services are provided for your convenience only. If you use a third party service, you agree that: (i) we
          won’t be party to any transaction or contract with a third party that you may enter into (which may include,
          but is not limited to, a transaction or contract where the third party sells or otherwise provides Distributed
          Content, or access to Distributed Content, to you pursuant to that transaction or contract) in particular
          where we send you offers through the app; (ii) we will not be supplying those goods or services (including,
          where applicable, any Distributed Content) to you; and (iii) we won’t be liable to you for any loss or damage
          which you may suffer by using those third party websites, offers and/or services (including any use by you of
          Distributed Content) (iv) we will not be liable for any offers sent to you through the app, where the third
          party is not able to deliver on the offer you have been sent. You agree that you will not involve us in any
          dispute you may have with such third-party websites, offers and services.
          <br />
          <br />
          <Header.Subheader>10. OTHER GENERAL TERMS THAT APPLY</Header.Subheader>
          Where are these Terms available?
          <br />
          These Terms are available on the Website. We will not file or store a copy of these Terms for each interaction
          or transaction by you via the Website or the Services. We therefore recommend that you print and store or save
          a copy of these Terms for future reference. Can you transfer your rights under these Terms?
          <br />
          You may not assign, sub-license or otherwise transfer any of your rights or obligations set out in these Terms
          to any other person.
          <br />
          Can a third party enforce these Terms?
          <br />
          These terms do not create any rights that are enforceable by any person who is not a party to these Terms.
          However, in registering to become a user of our Services, you also accept the terms and conditions applicable
          to any services delivered by third parties. What happens if we do not enforce a right under these Terms
          against you? If we decide not to exercise or enforce a right that we have against you (e.g. as a result of you
          breaching these Terms), this does not prevent us from doing so at a later date. What happens if part of these
          Terms is not enforceable? If any provision of these Terms is found by any court or administrative body of
          competent jurisdiction to be invalid or unenforceable, such invalidity or unenforceability will not affect the
          other provisions of these Terms and they will remain in full force and effect. What happens if there is a
          dispute about these Terms? These Terms are available in English only and are governed by and construed in
          accordance with the laws of Sweden. Disputes arising in connection with these Terms are subject to the
          exclusive jurisdiction of the public Swedish courts, with Göteborg District Court as first instance.
          <br />
          <br />
          <Header.Subheader>11. DETAILS OF WHO TO CONTACT</Header.Subheader>
          You can email us with any questions, queries or complaints at info@detecht.se
          <br />
          <br />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
);
