import React from 'react';
import { Container, Loader, Grid, Image, Statistic, Header, Button } from 'semantic-ui-react';
import { BrowserRouter, Route } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';

import axios from 'axios';

import Measure from 'react-measure';
import ReactMapGL, { NavigationControl, Marker } from 'react-map-gl';
import WebMercatorViewport from 'viewport-mercator-project';
import PolylineOverlay from './PolylineOverlay';

import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import FAQ from './FAQ';

class Main extends React.Component {
  state = {
    coordinates: [],
    routeInfo: {},
    loading: true,
    width: 600,
    image: '',
    routeId: '',
    name: '',
    viewport: {
      width: '100%',
      height: 500,
      latitude: 50,
      longitude: 40,
      zoom: 12,
    },
  };

  componentDidMount() {
    const { token } = this.props.match.params;

    if (token && !['termsandconditions', 'privacypolicy', 'faq'].includes(token)) {
      axios
        .get(`https://us-central1-detecht-a7400.cloudfunctions.net/decodeShareLink/${token.split('?')[0]}`)
        .then(({ data: { image, coordinates, time, distance, avgSpeed, routeId, name } }) => {
          coordinates = coordinates.map(({ latitude, longitude }) => [longitude, latitude]);

          const { minLong, minLat, maxLong, maxLat } = coordinates.reduce(
            (acc, [long, lat]) => ({
              minLong: !acc.minLong || long < acc.minLong ? long : acc.minLong,
              minLat: !acc.minLat || lat < acc.minLat ? lat : acc.minLat,
              maxLong: !acc.maxLong || long > acc.maxLong ? long : acc.maxLong,
              maxLat: !acc.maxLat || lat > acc.maxLat ? lat : acc.maxLat,
            }),
            {}
          );

          const { longitude, latitude, zoom } = new WebMercatorViewport({ width: 800, height: 600 }).fitBounds(
            [
              [minLong, minLat],
              [maxLong, maxLat],
            ],
            {
              padding: 40,
              offset: [0, 100],
            }
          );

          const routeInfo = {};
          if (time) {
            routeInfo.time = time;
          }
          if (avgSpeed) {
            routeInfo.avgSpeed = `${avgSpeed.toFixed(1)} km/h`;
          }
          if (distance) {
            routeInfo.distance = `${distance.toFixed(1)} km`;
          }

          this.setState({
            coordinates,
            routeInfo,
            routeId,
            viewport: { ...this.state.viewport, longitude, latitude, zoom },
            loading: false,
            image,
            name,
          });
        })
        .catch((error) => {
          this.setState({ loading: false, error: true });
        });
    }
  }

  render() {
    if (this.props.match.params.token === 'termsandconditions') {
      return <TermsAndConditions />;
    } else if (this.props.match.params.token === 'privacypolicy') {
      return <PrivacyPolicy />;
    } else if (this.props.match.params.token === 'faq') {
      return <FAQ />;
    }
    const { loading, error, routeId, name, coordinates } = this.state;

    if (loading) {
      return <Loader active size="massive" />;
    }

    if (error) {
      return (
        <Container className="shadow" style={{ height: '100vh', background: '#111', padding: 240 }}>
          <Header as="h1" style={{ fontWeight: '200', color: '#fff', textAlign: 'center' }}>
            Invalid sharing link
          </Header>
        </Container>
      );
    }

    return (
      <Measure bounds onResize={({ bounds: { width, height } }) => this.setState({ width, height })}>
        {({ measureRef }) => (
          <Container className="shadow" style={{ height: '100vh', background: '#111' }}>
            <div ref={measureRef}>
              <Grid columns="equal" style={{ background: '#111', margin: 0 }}>
                <Grid.Row style={{ marginBottom: -12 }}>
                  <Grid.Column verticalAlign="bottom" style={{ marginBottom: 4 }}>
                    <Header as="h1" style={{ color: '#fff', fontWeight: '100' }}>
                      {name}
                    </Header>
                  </Grid.Column>
                  <Grid.Column>
                    <div style={{ height: 100, background: '#111', paddingTop: 42, paddingRight: 20 }}>
                      <a href="https://www.detecht.se/" target="_blank" rel="noopener noreferrer">
                        <Image style={{ float: 'right', width: 200 }} src="logo_white.png" alt="Detecht" />
                      </a>
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <ReactMapGL
                  mapboxApiAccessToken="pk.eyJ1IjoiYW5keWFmayIsImEiOiJjandrZ2F4NWIwdHRiM3lwYmFsaHpmZTdxIn0.tu1isoGqehhcAFnRqwcsHw"
                  {...this.state.viewport}
                  width={this.state.width}
                  height={this.state.width > 640 ? this.state.viewport.height : 400}
                  longitude={this.state.viewport.longitude}
                  latitude={this.state.viewport.latitude}
                  zoom={this.state.viewport.zoom}
                  onViewportChange={(viewport) => {
                    this.setState({ viewport });
                  }}
                >
                  <PolylineOverlay points={coordinates} />
                  <Marker latitude={coordinates[0][1]} longitude={coordinates[0][0]} offsetLeft={-14} offsetTop={-24}>
                    <img src={'/start.png'} style={{ width: 26, height: 26 }} />
                  </Marker>
                  <Marker
                    latitude={coordinates[coordinates.length - 1][1]}
                    longitude={coordinates[coordinates.length - 1][0]}
                    offsetLeft={-14}
                    offsetTop={-24}
                  >
                    <img src={'/end.png'} style={{ width: 26, height: 26 }} />
                  </Marker>
                  <div style={{ position: 'absolute', right: 10, top: 10 }}>
                    <NavigationControl />
                  </div>
                </ReactMapGL>
                {Boolean(Object.keys(this.state.routeInfo).length) && (
                  <Grid.Row>
                    {Object.keys(this.state.routeInfo).map((key) => (
                      <Grid.Column
                        key={`route-info-${key}`}
                        style={{ textAlign: 'center', marginTop: 20, marginBottom: 24 }}
                      >
                        <Statistic size="small">
                          <Statistic.Value style={{ color: '#fff', textTransform: 'lowercase' }}>
                            <Image src={`${key}.png`} style={{ margin: '0 auto 12px auto' }} />
                            <span style={{ fontSize: this.state.width > 640 ? 42 : 30 }}>
                              {this.state.routeInfo[key].split(' ')[0]}
                            </span>
                          </Statistic.Value>
                          {this.state.routeInfo[key].split(' ')[1] &&
                          this.state.routeInfo[key].split(' ')[1] !== 'undefined' ? (
                            <span style={{ color: '#fff', fontSize: this.state.width > 640 ? 28 : 20, marginTop: 8 }}>
                              {this.state.routeInfo[key].split(' ')[1]}
                            </span>
                          ) : (
                            ''
                          )}
                        </Statistic>
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                )}

                <div className="desktop">
                  <Grid.Row>
                    <Grid.Column>
                      <Button
                        as="a"
                        href={`https://us-central1-detecht-a7400.cloudfunctions.net/onRequestGenerateGPX?route=${routeId}`}
                        basic
                        style={{ width: 200 }}
                        inverted
                        icon="download"
                        content="GPX"
                      />
                      <a
                        href="https://itunes.apple.com/se/app/detecht-din-motorcykel-app/id1373032762"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image src="google_play.png" style={{ float: 'right', height: 38 }} />
                      </a>
                      <a
                        href="https://play.google.com/store/apps/details?id=se.app.detecht&amp;hl=sv"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image src="app_store.png" style={{ float: 'right', height: 38, marginRight: 12 }} />
                      </a>
                    </Grid.Column>
                  </Grid.Row>
                </div>
                <div className="mobile">
                  <Grid.Row>
                    <Grid.Column>
                      <Button
                        as="a"
                        href={`https://us-central1-detecht-a7400.cloudfunctions.net/onRequestGenerateGPX?route=${routeId}`}
                        fluid
                        basic
                        style={{ marginBottom: 16 }}
                        inverted
                        icon="download"
                        content="GPX"
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Image
                        fluid
                        as="a"
                        href="https://play.google.com/store/apps/details?id=se.app.detecht&amp;hl=sv"
                        target="_blank"
                        rel="noopener noreferrer"
                        src="app_store.png"
                        style={{ float: 'left', width: '44%' }}
                      />
                      <Image
                        fluid
                        href="https://itunes.apple.com/se/app/detecht-din-motorcykel-app/id1373032762"
                        target="_blank"
                        rel="noopener noreferrer"
                        src="google_play.png"
                        style={{ float: 'right', width: '48%' }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </div>
              </Grid>
            </div>
          </Container>
        )}
      </Measure>
    );
  }
}

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Route exact path="/" render={() => (window.location = 'https://www.detecht.se')} />
        <Route path="/:token" component={Main} />
      </BrowserRouter>
    );
  }
}

export default App;
