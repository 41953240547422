import React from 'react';
import { Container, Grid, Header } from 'semantic-ui-react';

export default () => (
  <Container className="shadow" style={{ background: '#222' }}>
    <Grid columns="equal" style={{ background: '#222', margin: 0 }}>
      <Grid.Row style={{ marginBottom: -12 }}>
        <Grid.Column verticalAlign="bottom" style={{ marginBottom: 4 }}>
          <Header as="h1" style={{ color: '#fff', fontWeight: '100' }}>
            Detecht PRIVACY POLICY
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column style={{ color: '#fff' }} className="white-text">
          <br />
          <br />
          <Header.Subheader>Introduction</Header.Subheader>
          This notice sets out the basis on which any personal data we collect from you, or that you provide to us, will
          be processed by us. Please read the following carefully to understand our views and practices regarding your
          sensitive information and how we will deal with it. For the purposes of the EU General Data Protection
          Regulation (‘the GDPR’), sensitive information includes what is defined as your ‘personal data’. By visiting
          www.detecht.se (the ‘Website’) or the Detecht mobile application (our ‘App’) you accept and consent to the
          practices described in this Privacy Notice including the processing of your personal data under this lawful
          basis.
          <br />
          <br />
          <Header.Subheader>Who We Are</Header.Subheader>
          More formally, we are soon to be Detecht Technologies AB. We are a company registered in Sweden with the
          Company number 559176-8584 and our registered office is at Gothenburg, Stena Center, Holtermansgatan 1, 411 29
          Västra Götaland. In-line with the GDPR, Detecht AB is the Data Controller. If you have any concerns about the
          way we use your information or any questions about this Privacy Notice, please let us know. We can be
          contacted via email at info@detecht.se or you can write to us at the address above or contact us using our
          Website www.detecht.se. Any reference to the ‘Services’ in this Privacy Notice refers to the commercial
          services that we offer to our customers subject to this Privacy Notice and our Terms of Use and as are
          outlined on the Website and the App.
          <br />
          <br />
          <Header.Subheader>What we do with your information</Header.Subheader>
          We will use your information to: deliver our newsletter to you and to send you information about the Services
          that we offer and other related topics; monitor, develop and improve the Services and/or your experience. This
          may include personalising the advertising and content you see, based on the details given by you and/or your
          activity on the Services; assist in and administer the provision of Services to you, including the
          transmission to the emergency services of location and identification data and any medical data you choose to
          store in the app in the event that the crash detection function is triggered; process and deal with any
          complaints or enquiries made by or about you; investigate any suspected breach of the Terms of Use or the
          breach of other terms and conditions otherwise relating to you; to contact you with Service messages. By way
          of example only, if any of the Services for which you have registered is no longer available, we may contact
          you to let you know that it has closed and inform you of any similar services offered by us and/or a third
          party partner on our behalf; collect feedback from you that will be used to improve our Website, App and
          Services. to monitor compliance with the Terms of Use or other applicable terms and conditions including by
          way of (i) checking postings or submissions in connection with your use of the Services, (ii) retaining forum
          or chat room exchanges, and (iii) using keyword triggers to investigate usage that may be inappropriate;
          and/or where you are a prize winner, to administer the prize and to publish or otherwise make available a list
          of prize winners. We compile statistics about user trends on our Website and App, which are used by third
          party organisations to understand how users interact with businesses, brands and one another online and to
          advise about these things. These statistics are drawn from a dataset which does not contain any information
          from which you can be identified. Information we collect from you
          <br />
          <br />
          <Header.Subheader>We will collect and process the following information about you:</Header.Subheader>
          Information you give us – this is information about you that you give us by filling in forms on our Website,
          our App or by corresponding with us by phone, email or otherwise. It includes information you provide when you
          sign up to a subscription through the Website or App. We only request from you the minimum data required for
          you to use our service. To sign up to our Services we will ask you for your email, your full name, date of
          birth and your city. You may also want to upload a profile picture to supplement your account information too.
          You will also have the option to supplement your profile by adding medical data to your profile on our App.
          Adding medical data will allow you to take full advantage of the Services that we offer. This data will be
          passed securely directly onto the SOS (112) services. Information we collect about you – Whenever you visit
          our Website and our App we may automatically collect the following information: technical information –
          including the Internet protocol (IP) address used to connect your computer to the Internet, your login
          information, browser type and version, time zone setting, browser plug-in types and versions, operating system
          and platform. Access to your GPS location is an essential part of the Services that we deliver and therefore
          we will also collect this from you (with your permission). information about your visit – this includes the
          full Uniform Resource Locators (URL), clickstream to, through and from our Website (including date and time),
          pages you have visited, page response times, download errors, length of visits to certain page and page
          interaction information (such as scrolling, clicks, and mouse-overs). Information we receive from other
          sources – This is information we receive about you if you use any of the other websites we operate or the
          other services we provide. In this case we will have informed you when we collected that data if we intend to
          share those data internally and combine it with data collected on this Website. We will also have told you for
          what purpose we will share and combine your data. We are working closely with third parties (including, for
          example, business partners, sub-contractors in technical, payment and delivery services, advertising networks,
          analytics providers, search information providers, credit reference agencies). We will notify you when we
          receive information about you from them and the purposes for which we intend to use that information.
          <br />
          <br />
          <Header.Subheader>Where we hold and what we do with your information</Header.Subheader>
          The information and content held on our Website is deployed geographically to maximise user experience. All
          information that could identify individuals within the European Union is stored exclusively within the
          European Union. We will never share your user information with third parties for promotional purposes. Our
          website is hosted by WordPress. We remain responsible at all times for the security of your information, but
          if you want to know more about how we interact with WordPress you can view their Privacy Notice, amongst their
          other policies here. Our email marketing is delivered to you by MailChimp and you can view more information
          about MailChimp and how they handle personal data by visiting their website here. We may also disclose your
          information to third parties to whom we may choose to sell, transfer, or merge parts of our business or our
          assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our
          business, then the new owners may use your personal data in the same way as set out in this Privacy Notice.
          <br />
          <br />
          <Header.Subheader>What we do to ensure the security of your personal information</Header.Subheader>
          We take the security of your personal data very seriously. Our approach to information security is constantly
          evolving and continually reviewed. We have adopted industry best practices from both technological and
          business process perspectives in order to make the security of your data a key part of the way we do business.
          All our customer data and data collected through the Website or the App is stored on our SSL secured servers.
          We have policies and practices in place that ensure our compliance under the GDPR, including training and
          adequate procedures put in place for any staff that handle or have access to sensitive information.
          <br />
          <br />
          <Header.Subheader>Your rights regarding your personal information</Header.Subheader>
          We may contact you via email, SMS or push notification with updates about the services that we offer or any
          changes that we have made to our Website. You can opt in or out at any time by clicking the ‘Unsubscribe’ link
          in our emails. To control and turn push notifications on or off on mobile, open the Detecht app and tap on
          Profile in the navigation bar. Click on settings and notifications to control the notifications you would like
          to receive. You can amend all of your communication preferences at any time through the App. Our Website may,
          from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates
          (including, but not limited to, websites on which our service may be advertised). If you follow a link to any
          of these websites, please note that these websites and any services that may be accessible through them have
          their own privacy policies and that we are not responsible or liable for these policies or for any personal
          data that may be collected through these websites or services, such as contact and location data. Please check
          these policies before you submit any personal data to these websites or use these services.
          <br />
          <br />
          <Header.Subheader>Your Rights</Header.Subheader>
          You have the right to find out about what information we hold about you. You can exercise that right by
          contacting us and we will send you any request for information in a suitable electronic form within 20 working
          days. To make a request for information please contact info@detecht.se and a member of our team will be in
          touch. If your personal data is incorrect then you have the right to rectify this information and ensure that
          it is accurate and up to date. If your data is incorrect then please contact us at the email address above and
          a member of the team will rectify this on your behalf. You have the right ‘to be forgotten’ and to have your
          personal identifiable information permanently deleted from our systems. Again, if you would like to exercise
          this right then please contact us at info@detecht.se or open the Detecht in to the App, go to profile in the
          navigation bar. Click on Settings and choose delete user data. There will be no charge made for reasonable
          electronic access to your information, your right to rectification or for your right to be forgotten from our
          systems. How long we hold your information for At Detecht we have procedures in place to regularly review
          every months what personal data we hold. If you have not interacted with us or accessed our services during
          this or the subsequent period then we will delete your personal data from our systems, but will send you an
          email first informing you of our intention to do so. Of course, you have the right to be forgotten at any
          point and can find out more about this in the ‘Access to Information’ section above.
          <br />
          <br />
          <Header.Subheader>Changes to this Privacy Notice</Header.Subheader>
          Any changes we may make to our Privacy Notice in the future will be posted on this page. The new terms may be
          displayed on-screen and you may be required to read and accept them to continue your use of our Website and
          App.
          <br />
          <br />
          <Header.Subheader>Our Cookie Policy</Header.Subheader>
          If you’d like to manage you use of cookies or completely turn off cookies then you can find out how to do so
          by using the link here. Please note that if you do limit of block cookie use on our Website the functionality
          of both the Website and our services will be effected. Our Website uses cookies to distinguish you from other
          users of our Website. This helps us to provide you with a good experience when you browse our Website and also
          allows us to improve our site. By continuing to browse the Website, you agree and consent to our use of
          cookies. A cookie is a small file of letters and numbers that we store on your browser or the hard drive of
          your computer if you agree. Cookies contain information that is transferred to your computer’s hard drive. As
          part of our overall approach to privacy and transparency, this section describes what cookies are in the
          context of our web and mobile interfaces, and what their use means to you. At the end, we’ve included some
          links to help you research cookies and their impact, and how you can use your web browser to control the way
          it manages cookies.
          <br />
          <br />
          <Header.Subheader>We use the following types of cookies:</Header.Subheader>
          Strictly necessary cookies – these are cookies that are required for the operation of our Website. They
          include, for example, cookies that enable you to log into secure areas of our Website, use a shopping cart or
          make use of e-payment system. Analytical/performance cookies – these allow us to recognise and count the
          number of visitors and to see how visitors move around our Website when they are using it. This helps us to
          improve the way our Website works, for example, by ensuring that users are finding what they are looking for
          easily. Functionality cookies – these are used to recognise you when you return to our Website. This enables
          us to personalise our content for you, greet you by name and remember your preferences (for example, your
          choice of language or region). Targeting cookies – these cookies record your visit to our Website, the pages
          you have visited and the links you have followed. We will use this information to make our Website and the
          advertising displayed on it more relevant to your interests. We may also share this information with third
          parties for this purpose but these cookies will contain no information capable of identifying you personally
          to us. You can find more information about the individual cookies we use and the purposes for which we use
          below:
          <br />
          <br />
          <Header.Subheader>Google Analytics</Header.Subheader>
          We use Google Analytics to understand general trends about our content and traffic sources; for example, where
          users come from, which pages are most popular, which sites provide most traffic, how our marketing efforts
          impact the amount of visits we receive. Google’s statement about privacy can be found here.
          <br />
          <br />
          <Header.Subheader>Facebook button</Header.Subheader>
          We use Facebook button to allow you to share out platform with your friends and other Facebook users.
          Facebook’s statement about privacy relating their Connect feature is here and also here. LinkedIn button We
          use a LinkedIn button to allow you to share our platform and the services we offer with your connections.
          LinkedIn’s information about privacy can be found here.
          <br />
          <br />
          <Header.Subheader>Instagram button</Header.Subheader>
          We use an Instagram button to allow you to easily follow our Instagram account. You can find out more about
          how Instagram handle your personal data by visiting their statement on Privacy here.
          <br />
          <br />
          <Header.Subheader></Header.Subheader>More information about cookies Still don’t know what cookies are? Then
          why not follow the link conveniently located here which provides more information about what they are and how
          they work.
          <br />
          <br />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
);
